import day0 from './data/ebay-11-8-24.json';
import day1 from './data/ebay-12-8-24.json';
import day2 from './data/ebay-13-8-24.json';
import day3 from './data/ebay-15-8-24.json';
import day4 from './data/ebay-19-8-24.json';
import day5 from './data/ebay-23-8-24.json';
import day6 from './data/ebay-26-8-24.json';

export const allEbayDays = [day0, day1, day2, day3, day4, day5, day6];

import wall_day0 from './data/wallmart-14-8-24.json';

export const allWallmarkDays = [wall_day0];

