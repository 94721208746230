import React from 'react';
import logo from './logo.svg';
import './App.css';
import EbayTablePage from './pages/EbayTablePage';
import WallmartTablePage from './pages/WallmartTablePage';



const App = () => {
	return <div >
		{
			location.href.includes('wallmart') ? <WallmartTablePage /> : <EbayTablePage />
		}
		{

		}
	</div>
}

export default App;
